<template lang="pug">
  .organization-list
    TopBar(
      :filters="filters"
      @change-filters="applyFilters"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading && !$root.modalOpened")
      VueGoodTable.organizations-table(
        :rows="organizationList"
        :columns="columns"
        :sort-options="{ initialSortBy: sorting }"
        @on-sort-change="handleSorting"
      )
        template(v-slot:table-column="props")
          span(:title="props.column.label") {{ $t(props.column.label) }}
        template(v-slot:table-row="{ column, row, formattedRow }")
          .organization-name-field(v-if="column.field === 'name'")
            | {{ row.name }}
          .ota-names-field(v-else-if="column.field === 'ota'")
            AppListExpandable(
              :key="`otas-${row.id}`"
              :items="otaNames(row.otas)"
              valueKey="id"
              titleKey="name"
            )
          .table-actions(v-else-if="column.field === 'actions'")
            AppIconButton.action-button(
              :class="{ disabled: false }"
              icon="edit"
              @click="editOrganization(row)"
            )
            AppIconButton.action-button(
              :class="{ disabled: false }"
              icon="trash-alt"
              @click="deleteOrganization(row)"
            )
          span(
            v-else
            :title="formattedRow[column.field]"
          ) {{ formattedRow[column.field] }}
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count",
        :per-page="pagination.per_page"
        :auto-scroll-on-pagination="{ target: '.organization-list' }"
        @change-pagination-data="changePagination"
      )

</template>

<script>
  // store modules
  import organizationsModule from "@/config/store/maestro/organizations"

  // mixins
  import withModal from "@/mixins/withModal"
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"
  import withConfirmation from "@/mixins/withConfirmation"

  // components
  import { VueGoodTable } from "vue-good-table"
  import OrganizationForm from "./OrganizationForm"

  // misc
  import { map, isEmpty, isEqual } from "lodash-es"
  import { mapGetters } from "vuex"
  import "vue-good-table/dist/vue-good-table.css"
  import { columns } from "./OrganizationsTableConfig"

  const organizationsMixin = withStoreModule(organizationsModule, {
    resetState: true,
    name: "organizations",
    readers: {
      organizations: "items",
      organizationsLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: { fetchOrganizations: "FETCH_ITEMS", deleteAccount: "DELETE_ITEM" },
    mutations: {
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    }
  })

  export default {
    components: {
      VueGoodTable,
      TopBar: () => import("./TopBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppListExpandable: () => import("@/components/elements/AppListExpandable"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [organizationsMixin, withPermissions, withModal, withConfirmation],

    mounted() {
      this.$store.dispatch("FETCH_OTA_LIST")
      this.fetchOrganizations()
    },

    computed: {
      ...mapGetters(["translatedOtaNamesMapping"]),
      columns,

      loading({ organizationsLoading }) {
        return organizationsLoading
      },

      organizationList() {
        return isEmpty(this.organizations) ? [] : this.organizations
      }
    },

    methods: {
      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchOrganizations()
      },

      editOrganization(organization) {
        return this.openEditOrganizationModal(organization)
      },

      deleteOrganization({ id, name }) {
        this.$confirm({
          title: this.$t("organizations.delete_confirmation", { name }),
          resolve: {
            handler: () => this.deleteAccount(id)
          }
        })
      },

      openEditOrganizationModal(organization) {
        this.$openModal({
          component: OrganizationForm,
          title: this.$t("organization_management.organization_list.edit_organization"),
          size: "large",
          useConfirm: true,
          props: {
            organizationAccount: organization
          }
        })
      },

      handleSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchOrganizations()
      },

      applyFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchOrganizations()
      },

      otaNames(otas) {
        return map(otas, ({ id }) => ({ id: id, name: this.translatedOtaNamesMapping[id] }))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .organization-list
    .table-wrapper
      position: relative

    .organizations-table
      +listing-container(-30px)

    ::v-deep
      .vgt-table
        border: none

        .expandable-field
          max-width: 350px
        .id-field
          min-width: 40px
        .active-field
          min-width: 85px
        .vgt-right-align
          // overwrite text align for date fields
          text-align: left

        th
          background: $th-background-color
          border-bottom: 1px solid $default-purple-light-line
          border-left: 1px solid $border-element-color
          border-right: 1px solid $border-element-color
          border-top: none
          color: $default-purple
          font-size: 0.8rem
          font-weight: 700
          padding: 17px 7px

          &:first-child
            border-left: 1px solid $default-purple-light-line

          &:last-child
            border-right: 1px solid $default-purple-light-line

        td
          border-bottom: 1px solid $default-purple-light-line
          border-left: 1px solid $border-element-color
          border-right: 1px solid $border-element-color
          border-top: 1px solid $default-purple-light-line
          color: $td-color
          font-size: 0.8rem
          padding: 11px 7px 13px

          &:first-child
            border-left: 1px solid $default-purple-light-line

          &:last-child
            border-right: 1px solid $default-purple-light-line

          .organization-name-field
            white-space: nowrap

          @media screen and (max-width: 1300px)
            .ota-names-field
              max-width: 200px
          &.actions-field
            width: fit-content
            white-space: nowrap

          .table-actions
            text-align: center

            .app-icon
              fill: $default-purple
              height: 18px
              width: 18px

            .action-button
              +icon-button($default-purple)
</style>
